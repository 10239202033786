@import './index';

// ========================= Input =========================
.@{iconfont-css-prefix}.@{ant-prefix}-input-clear-icon {
  margin: 0 @input-affix-margin;
  color: @disabled-color;
  font-size: @font-size-sm;
  vertical-align: -1px;
  // https://github.com/ant-design/ant-design/pull/18151
  // https://codesandbox.io/s/wizardly-sun-u10br
  cursor: pointer;
  transition: color 0.3s;

  &:hover {
    color: @text-color-secondary;
  }

  &:active {
    color: @text-color;
  }

  &-hidden {
    visibility: hidden;
  }

  &:last-child {
    margin-right: 0;
  }
}

// ======================= TextArea ========================
.@{ant-prefix}-input-affix-wrapper-textarea-with-clear-btn {
  padding: 0 !important;
  border: 0 !important;

  .@{ant-prefix}-input-clear-icon {
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 1;
  }
}
